import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import lightLogo from '..//matt-logo-v8-black.png';
import darkLogo from '..//matt-logo-v8-white.png';

function NavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleClick = (event) => {
      if (isMenuOpen && event.target.classList.contains('navbar-item')) {
        toggleMenu();
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [isMenuOpen]);

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
      <Link className="navbar-item" to="/">
          <picture>
            <source media="(prefers-color-scheme: dark)" srcSet={darkLogo} />
            <source srcSet={lightLogo} />
            <img className="App-logo" alt="logo" />
          </picture>
        </Link>

        <a
          role="button"
          className={`navbar-burger ${isMenuOpen ? 'is-active' : ''}`}
          aria-label="menu"
          aria-expanded={isMenuOpen}
          onClick={toggleMenu}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" className={`navbar-menu ${isMenuOpen ? 'is-active' : ''}`}>
       
        <div className="navbar-end">
          
            <Link className="navbar-item" to="/" onClick={toggleMenu}>Work</Link>          
            {/* <Link className="navbar-item" to="/blog" onClick={toggleMenu}>Blog</Link> */}
            <Link className="navbar-item" to="/about" onClick={toggleMenu} >About</Link>
          <a className="navbar-item" href="https://mattwhitfield.xyz/Media/matt-whitfield-resume.pdf" target="_blank">
            <span className="icon-text">
              <span className="icon">
                <span class="material-symbols-outlined">
                  picture_as_pdf
                </span>
              </span>
              <span>Resume</span>
            </span>
          </a>
          <div className="navbar-item">
            <div className="buttons">
              <a className="button is-primary" href="https://www.linkedin.com/in/matt-whitfield-ux/" target="_blank">
                <span className="icon-text">
                  <span className="icon">
                    <span class="material-symbols-outlined">
                      arrow_outward
                    </span>
                  </span>
                  <span>
                    LinkedIn
                  </span>
                </span>
              </a>
              <a className="button is-light" href="mailto:hello@mattwhitfield.xyz">
                <span className="icon-text">
                  <span className="icon">
                    <span class="material-symbols-outlined">
                      mail
                    </span>
                  </span>
                  <span>
                    Email
                  </span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
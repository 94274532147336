// import logo from './matt-logo-v8-black.png';
// import ProfilePhoto from '.../media/linkedIn-profile-picture.jpg';
import { useEffect } from 'react';
import JourneyMap from './google-ux-cert-user-journey-map.jpg';
import ImageGallery from '../../components/ImageGallery';

function FoodFriendly() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    return (
        <div>
        
        <section className='hero has-background-primary-00'>
        <div className='hero-body'>
            
            
             <div className='columns is-align-items-center'>
              <div className='column is-two-thirds'>
                <p className='title is-1 has-text-primary'>Amplify.ux</p>
                <p className='subtitle has-text-primary-95'>A Browser Companion for Emotional Resilience and a Brighter, more Personable Job Search.</p>
              </div>
              <div className='column'>
                  <figure>
                  <img src="https://mattwhitfield.xyz/Media/amplify-ux/amplify-ux-splash.webp" width="50%" alt="Amplify.UX Splash/Welcome Page. A breath of fresh air with watercolor animations."/>
                </figure>
                </div>
              </div>
            
             
              
         
              
        </div>
      </section>

      {/* Overview Content Start */}
      <section>
        <article className='section '>
          <div className='block'>
            <h1 className='title has-text-centered'>Overview</h1>
          </div>
          <article className='message'>
            <div className='message-body'>
            <strong> Amplify.ux </strong> is an <a href="https://www.ampupdesign.com/">Amp Up</a> product developed by <a href="https://techfleet.org/">TechFleet</a>. 
                    I worked on a 10 week contract as the Lead Product Strategist 
                    during <strong>product discovery</strong> with a team of more than 30 individuals.

            </div>
          </article>
          <div className='columns'>
            <div className='column is-two-thirds'>
              <div className='content'>
                <h2>Role</h2>
                <p>Lead Product Strategist</p>
                <h2>Problem</h2>
                <p>Job seekers face mental health challenges due to a complex and stressful job search process. The current networking and job search tools often feel artificial and negatively impact mental wellbeing.</p>
                <h2>Solution</h2>
                <p>Design and test a proof of concept (POC) that prioritizes features focused on improving mental wellbeing.</p>
              </div>
            </div>
            <div className='column'>
              <div className='content'>
                <h2>Responsibilities</h2>
                <ul>
                <li>Lead scrum ceremonies in agile product development</li>
                <li>Product direction</li>
                <li>Market Analysis</li>
                <li>Sprint goals</li>
                <li>Facilitate cross-functional collaboration</li>
                <li>Product requirements</li>
                <li>Product roadmap</li>
                <li>Product backlog</li>
                </ul>
              </div>
              
            </div>
          </div>
         </article>
       </section>
         {/* Start Section Two */}
       <section>
        <article className='section has-background-white-bis  has-text-black-bis'>
            <div className='block'>
            <span className='tag is-dark'>Getting Started</span>
            </div>
            <div className='content '>
                <div className='columns is-align-items-center'>
                    <div className='column'>
                        <div className='content'>
                            <h2 className='has-text-black-bis'>Navigating Ambiguity</h2>
                            <p>Facing uncertainty, I took the lead and collaborated with key stakeholders to gain their buy-in. By making baseline assumptions, I focused our team's direction and narrowed our scope to a testable proof-of-concept.
                            </p>
                        </div>
                    </div>
                    <div className='column'>
                        <div className='content'>
                            <figure>
                            <img src="https://mattwhitfield.xyz/Media/amplify-ux/okr-amplify-ux.webp"
                            alt="Outcomes and Key Results Image" />
                            <figcaption>OKR's: Working with the Project Management Team, we developed OKR's (outcomes, key results) for the project phase. The purpose of this was to ensure we made tangible progress during each sprint.</figcaption>
                        </figure>
                        </div>
                    </div>
                </div>
            </div>
        </article>
        <article className='section'>
        <div className="columns is-align-items-center">
            <div className='column'>
                <div className="column">
                    <div className="grid is-col-min-8">
                        {/* Cell 1 START */}
                        <div className="cell">
                            <div className="card">
                                <div className="card-image">
                                    <figure className="image is-square">
                                        <img
                                        src="https://mattwhitfield.xyz/Media/amplify-ux/kind-words-square.webp"
                                        alt="kind words feature screenshot"
                                        />
                                    </figure>
                                </div>
                                <div className="card-content">
                                    <div className="media">
                                        
                                        <div className="media-content">
                                        <p className="title is-6">Community Letters</p>
                                        <p className="subtitle is-6">Feature 1</p>
                                        </div>
                                    </div>

                                    <div className="content">
                                        <p>
                                        Allows users to send digital letters of encouragement to one another. Inspired by the steam game "Kind Words," and supported by the positive psychology technique of participating in random acts of kindness.
                                        </p> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Cell 2 START */}
                        <div className="cell">
                            <div className="card">
                                 <div className="card-image">
                                    <figure className="image is-square">
                                        <img
                                        src="https://mattwhitfield.xyz/Media/amplify-ux/social-connection-square.webp"
                                        alt="social connection app screenshot"
                                        />
                                    </figure>
                                </div>
                                <div className="card-content">
                                    <div className="media">
                                        <div className="media-content">
                                        <p className="title is-6">Social Connections</p>
                                        <p className="subtitle is-6">Feature 2</p>
                                        </div>
                                    </div>

                                    <div className="content">
                                        <p>
                                        This feature aims to providing a digital water cooler experience. Inspired by positive psychology research that suggests random social interactions with strangers can boost feelings of satisfaction and happiness.
                                        </p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='column'>
                <div className="column">
                    <div className="content">
                        <h2>Prioritizing High-Risk/High-Value</h2>
                        <p>Because the product space for job-seekers is well defined and highly competitive, we prioritized two high-risk, novel features that catered to our niche users. These features were driven by the following hypothesis:</p>
                        <blockquote> <strong><em>We believe that interactions backed by research in positive psychology can create feelings of positivity in the user and lead to increased user engagement/involvement.</em></strong>
                         </blockquote>
                    </div>
                </div>
            </div>
        </div>
    </article>
    <article className='section has-background-white-bis  has-text-black-bis'>
        <div>
            <div className='columns is-align-items-center'>
                <div className='column'>
                <div className='content'>
                <h2 className='has-text-black-bis'>Working in Parallel to Boost Velocity</h2>
                <p>Initial sprint goals guided team efforts toward delivering on the POC, and defining our target user more clearly. Simultaneously, my team worked hard in the background to conduct an in-depth competitive analysis and market research.</p>
                <p>This parallel work approach allowed us to accomplish two crucial goals: </p>
                <ul>
                    <li>Setting a well-informed path forward</li>
                    <li>Gaining valuable insights into our target market.</li>
                </ul>
                <p>As a result, our sprint velocity increased, and we established a robust foundation for future product development.</p>
                </div>
                </div>
                <div className='column'>
                    <div className='content'>
                        <figure >
                        <img src="https://mattwhitfield.xyz/Media/amplify-ux/market-research-amplify-ux.webp" alt="Market Analysis Tool for feature prioritization" />
                        <figcaption>Our three-tiered approach to assessing the market. (1) Market overview; (2) Identification of core features; (3) Feature prioritization matrix to determine market requirements.</figcaption>
                        </figure>
                    </div>
                </div>
            </div>
        
            
        </div>
        </article>
      </section>
      {/* Start Section Three */}
      <section>
        <article className='section'>
          <div className='block'>
          <span className='tag is-dark'>Takeaways</span>
          </div>
          <div className='columns is-align-items-center'>
            <div className='column'>
                <div className='content'>
                  <figure>
                    <img src="https://mattwhitfield.xyz/Media/amplify-ux/searching-for-product-market-fit-midjourney.webp" alt="Searching for the right piece to a complex puzzle"/>
                    <figcaption>
                     Our team moved the needle during phase 02 of this project but we were unable to determine a good product-market fit. Credit: Midjourney.
                  </figcaption>
                  </figure>
                  
                </div>
            </div>
            
            <div className='column'>
              <div className='content'>
                <h2>Searching for a Market Fit</h2>
                <p>Research results based on interviews and surveys revealed that mental health is not a primary concern for job seekers, indicating low demand for mental wellbeing tools. </p>
                <p>However, research did uncover a demand for better, more authentic, professional networking tools. Additionally, the market demand for browser tools aimed at simplifying content and improving productivity offers a unique opportunity in the wellbeing space.
                </p>
              </div>
            </div>
          </div>
          <section className='section has-background-white-bis '>
            {/* Begin Columns */}
            <div className='columns'>
              {/* Column One */}
            <div className='column'>
                <div className='content'>
                  <div className='card'>
                      <div className='card-content'>
                      <h2>Solve the <em>Right </em>Problem</h2>
                        <p>There are a plethora of job search tools on the market and the technology is constantly evolving. While there is a clear need for tools that better serve the end user, especially when it comes to authentic networking and providing emotional support, there is a lack of market demand for such a tool, especially in the browser extension landscape. Further exploration into the problem space is necessary in order to fully determine the product's value and viability.</p>
                        </div>
                  </div>
                </div>
            </div>
            {/* Column Two */}
            <div className='column'>
                <div className='content'>
                    <div className='card'>
                    <div className='card-content'>
                    <h2>Design Leadership</h2>
                    <p>Working on this project was professionally fulfilling on multiple levels. The opportunity to tackle an ambiguous product challenge and steer a team of over 30 capable designers and researchers was tremendous. I’m especially proud of my ability to foster a collaborative work environment and to have achieved early team alignment.</p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='columns'>
             {/* Column Three*/}
             <div className='column'>
            <div className='content'>
            <div className='card'>
                <div className='card-content'>
                <h2>Test Results</h2>
                <ul>
                    <li><strong>Social Connections:</strong>Users desired 1:1 connections and real-time interactions, with 90% finding the concept appealing.</li>
                    <li><strong>Community Letters:</strong>80% of users did not see the value, with active users preferring 1:1 chats and passive users hesitant to engage.</li>
                </ul>
                <p>While I think the testing scenarios were imperfect and led to some user confusion in terms of the community letters feature, I support the overall findings: this feature is not worth pursuing at this time. Social Connections, on the other hand, uncovered a unique user pain point: current networking platforms are frustrating, and a professional networking tool that promotes more meaningful – and immediate – interactions would have a strong market for early-career tech job-seekers.</p>
                </div>
            </div>
            </div>
            </div>
        </div>
          </section>

          <article className='section'>
           <h2 className='title'>Deliverables</h2>
            <ImageGallery/>
          </article>
        </article>
      </section>
        
    </div>
        
    )
}
export default FoodFriendly
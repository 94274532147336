import { useEffect } from 'react';
import { Link } from 'react-router-dom';
// import 'bulma/css/bulma.min.css';
import ProfilePhoto from '../main/linkedIn-profile-picture.jpg';
import WorkGallery from '../../components/WorkGallery';




function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <section className="hero">
        <div className="hero-body has-text-centered is-flex-direction-column">
          <div className= "block is-flex is-justify-content-center">
            <figure class="image is-128x128">
                <img class="is-rounded" src={ProfilePhoto} alt="Handsome, approachable guy smiling" />
                <figcaption>Matt Whitfield</figcaption>
              </figure>
          </div>
          <div className='block'>
            <h1 className="title">Empowering Users, Driving Solutions</h1>
          </div>
          <div className='block'>
            <div className='message'>
              <div className="message-body">  
                <h4 className='subtitle'><strong>A Storyteller’s Path to Tech</strong></h4>
                <p >
                An actor-turned-UX-champion, I leverage fifteen years of professional adaptability and stellar customer service to deliver stand out product experiences. I use a human-centered approach, and excel at developing authentic rapport and creating tailored solutions.
                </p>
                <Link className="button is-text" to="/about">About Me</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <-- Portfolio Section --> */}
      <section className="section">
        <div className='block has-text-centered'>
            <h2 className='title is-4'>Selected Work</h2>
            <span className="icon">
            <span className="material-symbols-outlined">
              arrow_downward
            </span>
            </span>
        </div>
        <div className='container'>
        <WorkGallery/>
          
        </div>
        
      

      </section>
    </div>
  );
}

export default App;

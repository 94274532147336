import React from 'react';
// import './ImageCarousel.css'; // Import the CSS file

const ImageCarousel = () => {
  const images = [
    {
      src: 'https://mattwhitfield.xyz/Media/amplify-ux/product-roadmap-amplify-ux.webp',
      alt: 'amplify-ux product roadmap',
      title: 'Amplify UX Phase 02 Roadmap',
      description: 'An easy to reference roadmap that guided the project team.',
    },
    {
      src: 'https://mattwhitfield.xyz/Media/amplify-ux/product-requirements-amplify-ux.webp',
      alt: 'screenshot of the product requirements outline',
      title: 'Product Requirements',
      description: 'A detailed product requirements document outlining technical hurdles, risks, and suggested a suggested go to market strategy.',
    },
    {
      src: 'https://mattwhitfield.xyz/Media/amplify-ux/product-backlog-amplify-ux.webp',
      alt: 'Screenshot of the product backlog',
      title: 'Product Backlog',
      description: 'A prioritized list of tasks to be completed by the team. Notion was our collaborative software, and the backlog was constantly being updated.',
    },
    {
      src: 'https://mattwhitfield.xyz/Media/amplify-ux/market-analysis-amplify-ux.webp',
      alt: 'Screenshot of the market analysis document on Notion',
      title: 'Market Analysis Document',
      description: 'A detailed report on the current market and the likely demand for our product',
    },
    
      
  ];

  return (
    
      <div className="grid is-col-min-12">
        {images.map((image, index) => (
          <div className="cell" key={index}>
            <div className="card">
              <div className="card-image">
                <figure className="image is-square">
                  <img src={image.src} alt={image.alt} />
                </figure>
              </div>
              <div className="card-content">
                <div className="media">
                  <div className="media-content">
                    <p className="title is-4">{image.title}</p>
                    <p className="subtitle is-6">{image.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    
  );
};

export default ImageCarousel;
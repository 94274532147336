function PortTwoCol(){
    return(
        <section className='section'>
        <article className='section'>
        <div className='block'>
        <span className='tag'>Research</span>
        </div>
          <div className='columns'>
            <div className='column is-two-thirds'>
              <div className='content'>
                <h2>Where's the Value?</h2>
                <p>In order to empathize with users and define the high-value pain points to solve for, I conducted  interviews with restaurant managers, owners, and front of house employees. After that, I drew up some user-journey maps, personas, and user needs. Two problems stood out.</p>
                <div className='columns'>
                  <div className='column'>
                    <div className='card'>
                      <div className='card-content'>
                      <h5>Problem 1</h5>
                      <p>The need for a user-friendly tableside ordering system with mobile devices that allows servers to easily take accurate orders during shifts.</p>
                      </div>
                    </div>
                  </div>
                  <div className='column'>
                  <div className='card'>
                  <div className='card-content'>
                      <h5>Problem 2</h5>
                      <p>The need to improve server ability to accurately, and confidently, assist guests that have food allergy requirements.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
            <div className='column'>
              <div className='content'>
                <figure>
                  <img src="https://bulma.io/assets/images/placeholders/256x256.png" alt="placeholder image"/>
                </figure>
              </div>
            </div>
          </div>
          <div className='content'>
            <h3><em>“We need a system that’s flexible and doesn’t slow us down."</em></h3>
            <p>– Restaurant manager</p>
          </div>
        </article>
      </section>
    );
};
export default PortTwoCol
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
return (
        <>
            <section className="hero is-medium">
                <div className='hero-content has-text-centered'>
                <h1 className="title is-1">Privacy Policy</h1> 
                <div className='block'>
                <p className='subtitle'> Last updated: 05/07/2024</p> 
                </div>
                </div>
            </section>
            <div className="section">
              <div className="content">

                    <p>I take your privacy seriously and want to be transparent about how I handle your personal information on my website. Here's a summary of my privacy policy:</p>

                    <h2>Personal Data</h2>
                    <p>I don't collect any personal data or identifiable information from you when you visit my website or use my services.</p>

                    <h2>Cookies</h2>
                    <p>I also don't use cookies or similar tracking technologies. However, I do use web analytics tools like Google Analytics to understand how visitors interact with my site, but all data collected is anonymous.</p>

                    <h2>Using Your Info</h2>
                    <p>I use the limited data I collect to improve my services and respond to your requests or feedback.</p>

                    <h2>Sharing Your Info</h2>
                    <p>Your privacy is important to me. I won't sell or rent your personal info. I may share anonymous data with service providers like web analytics tools and disclose info if required by law or to protect rights/safety.</p>

                    <h2>Your Choices</h2>
                    <p>You can opt out of web analytics tracking if you prefer. Just use the opt-out tools provided by the analytics companies, like the Google Analytics opt-out browser add-on.</p>

                    <h2>Data Retention</h2>
                    <p>I only keep your info for as long as needed to provide my services and improve your experience.</p>

                    <h2>Security</h2>
                    <p>I take reasonable measures to protect your data from loss and unauthorized access.</p>

                    <h2>Children's Privacy</h2>
                    <p>My website is not for children under 13. I don't knowingly collect personal info from kids under 13.</p>

                    <h2>International Transfer</h2>
                    <p>Your info may be transferred and processed outside your country, including the US. By using my site, you consent to this transfer.</p>

                    <h2>Updates</h2>
                    <p>I may update this policy occasionally. I'll let you know by updating the "Last updated" date.</p>

                    <h2>Contact</h2>
                    <p>Feel free to reach out with any questions or concerns at <a href="mailto:hello@mattwhitfield.xyz">hello@mattwhitfield.xyz</a>.</p>

                    <p>Thanks for trusting me with your privacy! I'm happy to help and value your peace of mind. Enjoy your day!</p>
              </div>
            </div>
            </>
)
};
export default PrivacyPolicy;
// import logo from './matt-logo-v8-black.png';
// import ProfilePhoto from '.../media/linkedIn-profile-picture.jpg';
import { useEffect } from 'react';
import JourneyMap from './google-ux-cert-user-journey-map.jpg';


function FoodFriendly() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    return (
        <div>
        
        <section className='hero has-background-primary-00'>
        <div className='hero-body'>
            
            
             <div className='columns is-align-items-center'>
              <div className='column is-three-quarters'>
                <p className='title is-1 has-text-primary-00-invert'>Food Friendly: <em>A POS</em> that isn’t </p>
                <p className='subtitle has-text-primary-95'>A software platform for restaurants that increases employee confidence and improves customer experience. Without being a piece of<em> you-know-what</em>.</p>
              </div>
              <div className='column is-justify-content-center'>
                  <figure>
                  <img src="https://mattwhitfield.xyz/Media/food-friendly-hero_1.gif" width="80%" alt="food ordering app in action"/>
                </figure>
                </div>
              </div>
            
             
              
         
              
        </div>
      </section>

      {/* Overview Content Start */}
      <section>
        <article className='section '>
          <div className='block'>
            <h1 className='title has-text-centered'>Overview</h1>
          </div>
          <article className='message'>
            <div className='message-body'>
            <strong> Note: </strong>Food Friendly is a proof of concept designed as part of Google's UX design certificate program.
              I chose to address a personal pain point that I experienced as a restaurant employee.
            </div>
          </article>
          <div className='columns'>
            <div className='column is-two-thirds'>
              <div className='content'>
                <h2>Role</h2>
                <p>Product Designer</p>
                <h2>Problem</h2>
                <p>Restaurants are high-stress and fast-paced environments where communication between employees and guests has many opportunities to go wrong, especially when it comes to food allergies and menu updates.</p>
                <h2>Solution</h2>
                <p>Design an app that streamlines the communication workflow for front of house restaurant employees and simplifies allergy checks.</p>
              </div>
            </div>
            <div className='column'>
              <div className='content'>
                <h2>Responsibilities</h2>
                <ul>
                  <li>Research</li>
                  <li>Competitive audit</li>
                  <li>User interviews</li>
                  <li>Problem statements</li>
                  <li>User needs</li>
                  <li>Usability studies</li>
                  <li>Design</li>
                  <li>User personas</li>
                  <li>Wireframes</li>
                  <li>Mockups</li>
                  <li>low-fi/high-fi prototypes</li>
                </ul>
              </div>
              
            </div>
          </div>
         </article>
       </section>
         {/* Start Section Two */}
       <section>
        <article className='section has-background-white-bis has-text-black-bis'>
        <div className='block'>
        <span className='tag is-dark'>Research</span>
        </div>
        <div className='content'>
                <h2  className='has-text-black-bis'>The Who and the What</h2>
                <p>In order to empathize with users and define the high-value pain points to solve for, I conducted  interviews with restaurant managers, owners, and front of house employees. After that, I drew up some user-journey maps, personas, and user needs. Two needs stood out.</p>
                <div className='columns'>
                  <div className='column'>
                    <div className='card'>
                      <div className='card-content'>
                      <h5>Need 1</h5>
                      <p>A user-friendly tableside ordering system that allows servers to easily take accurate orders during shifts.</p>
                      </div>
                    </div>
                  </div>
                  <div className='column'>
                  <div className='card'>
                  <div className='card-content'>
                      <h5>Need 2</h5>
                      <p>Improve server ability to accurately, and confidently, assist guests that have food allergy requirements.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='columns is-align-items-center'>
                  <div className='column is-half'>
                  <div className='content'>
                    <a href="https://mattwhitfield.xyz/Media/Google%20UX%20Design%20Certificate%20-%20User%20Journey%20Map%20Matt%20W.pdf" target="_blank">
                      <figure >
                        <img src={JourneyMap} alt="Jude's user journey map" />
                        <figcaption>A simple user journey map for Jude, one of the user personas</figcaption>
                      </figure>
                    </a>
                </div>
                  </div>
                  <div className='column'>
                  <div className='content'>
                    <h3 className=' has-text-black-bis'><em>“We need a system that’s flexible and doesn’t slow us down."</em></h3>
                    <p>– Restaurant manager</p>
                  </div>
                  </div>
                </div>
                
                 
              </div>
        </article>
      </section>
      {/* Start Section Three */}
      <section>
        <article className='section'>
          <div className='block'>
          <span className='tag is-dark'>Design</span>
          </div>
          <div className='columns is-align-items-center'>
            <div className='column'>
                <div className='content'>
                  <figure>
                    <img src="https://mattwhitfield.xyz/Media/food-friendly-daily-specials.webp" alt="Food Friendly Mockup Screens"/>
                    <figcaption>
                    A calming blue was chosen for buttons, while orange was chosen as a primary color to maintain consistency with competitors, a strategy aimed at simplifying the learning curve for new users. 
                  </figcaption>
                  </figure>
                  
                </div>
            </div>
            
            <div className='column'>
              <div className='content'>
                <h2>Keep Cool, Keep Customers</h2>
                <p>Running out of things in the middle of service is frustrating, but it happens. Not knowing and having to go back to a customer and tell them that you don’t have what they ordered adds insult to injury. This impacts customer experience and can affect tips for the server and long term customer value for the restaurant. The question I asked myself: “How do I help?”</p>
              </div>
            </div>
          </div>
          <section className='section'>
            <div className='columns is-align-items-center'>
              {/* Column One */}
              <div className='column'>
                <div className='content'>
                  <div className='card'>
                      <div className='card-content'>
                      <h5>Gluten Free?</h5>
                        <p>I wanted to make it easy for servers to check if items have a specific ingredient, simplifying allergy checks during busy service.</p>
                        </div>
                       
                  </div>
                  </div>
                  
              <div className='columns'>
                  <div className='column'>
                    <div className='content'>
                      <div className='card'>
                        <div className='card-content'>
                        <h5>I Can't Find It</h5>
                        <p>Interviews inspired early wireframes focused on finding specific items with an easily accessible search feature.</p>
                      </div>
                      </div>
                    </div>
                  </div>
                  <div className='column'>
                    <div className='content'>
                    <div className='card'>
                      <div className='card-content'>
                        <h5>Daily Specials</h5>
                        <p>A dashboard screen with strong visual hierarchy <em>serves up</em> fast access to daily specials and live updates of 86'd (out of stock) items.</p>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
               {/* Column Two */}
                <div className='column'>
                <div className='column'>
                <div className='content'>
                  <figure>
                    <img src="https://mattwhitfield.xyz/Media/food-friendly-allergy-check-square.webp" alt="Allergy Check user flow mockups"/>
                  {/* <figcaption>
                    Sometimes servers don't know what ingredients are in each dish. It's a major problem. Seeking out answers is stressful and takes time. With allergy checks in the app, this creates confidence in the guest, and the server. A win-win. 
                  </figcaption> */}
                  </figure>
                </div>
            </div>
                </div>
            </div>
          </section>
          <article className='section'>
            <div className='columns is-align-items-center'>
              <div className='column'>
                  <div className='content'>
                    <figure>
                      <img src="https://mattwhitfield.xyz/Media/food-friendly-wireframe-square.webp" alt="wireframes of the food friendly app"/>
                      <figcaption>
                       These wireframes were used to create a low-fi prototype that was tested with users. The feedback was helpful, and the results surprised me. 
                      </figcaption>
                    </figure>
                  </div>
              </div>
              <div className='column'>
                <div className='content'>
                  <h2>How to Listen, Search, and Type</h2>
                  <p>Table-side ordering presents a challenge for servers, who must navigate a complex UI while engaging with customers. Testing revealed that while a search feature would be useful in some cases, most participants preferred using "categories" buttons to find items.</p>
                </div>
              </div>
            </div>
            <div className='section'>
            <div className='content'>
            <h3><em>"Yeah, I think it's cool but I don't think I'll have time to do this on busy nights. And we're always busy."</em></h3>
            <p>– User Feedback</p>
          </div>
            </div>
          </article>
        </article>
      </section>
      
      <section>
        <article className='section has-background-white-bis  has-text-black-bis'>
          <div className='block'>
          <span className='tag is-dark'>Takeaways</span>
          </div>
          <div className='columns is-align-items-center'>
            <div className='column'>
                <div className='content'>
                  <h2 className=' has-text-black-bis'>What Did I Get Myself Into?</h2>
                  <p>I really threw myself a curveball with this project, things became complicated fast. Juggling user interviews, testing out designs, and tweaking things based on feedback for multiple features at once felt like running down a hallway of treadmills, bouncing from one to the next, each running at a different speed, while also litterally juggling. Looking back, I realize I should have narrowed the focus. But you know what? It taught me heaps about the importance of having solid product requirements, user stories, and acceptance criteria.</p>
                </div>
            </div>
            <div className='column'>
                  <div className='content'>
                  <figure>
                      <img src="https://mattwhitfield.xyz/Media/treadmills-dalle2-.webp" alt="AI generated image of a hallway of treadmills in black and white oil paint"/>
                      <figcaption>
                       Credit: dalle2. I love running and a good challenge. That's why the thought of bouncing back and forth on treadmills of different speeds actually sounds like a good time. 
                      </figcaption>
                    </figure>
                  </div>
            </div>
              
              
            </div>
        </article>
      </section>
        
    </div>
        
    )
}
export default FoodFriendly
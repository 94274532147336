import{BroowserRouter, Routes, Route, BrowserRouter} from 'react-router-dom';
import { useEffect } from 'react';
import logo from './matt-logo-v8-black.png';
import NavBar from './components/NavBar.js'
import PortTwoCol from './components/PortfolioTwoColumn';
import Footer from './components/Footer';
import JourneyMap from './media/google-ux-cert-user-journey-map.jpg';
import WorkPage from './pages/main/Work.js';
import BlogPage from './pages/main/Blog.js';
import AboutPage from './pages/main/About.js';
import UXDPage from './pages/main/UXDesign.js';
import ProductPage from './pages/main/Product.js';
import EnablementPage from './pages/main/Enablement.js';
import FoodFriendly from './pages/portfolio/FoodFriendly.js';
import AmplifyUx from './pages/portfolio/AmplifyUx.js';
import PrivacyPolicy from './pages/main/PrivacyPolicy.js';
import './Gallery.css';


function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BrowserRouter>
        <div>
          <div className="matt-navbar">
            <NavBar />
          </div>
          <div>
            <Routes>
            
              <Route exact path="/" element={<WorkPage />} />
              <Route path="/blog" element={<BlogPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/ux-design" element={<UXDPage />} />
              <Route path="/product" element={<ProductPage />} />
              <Route path="/enablement" element={<EnablementPage />} />
              <Route path="/portfolio/food-friendly" element={<FoodFriendly />} />
              <Route path="/portfolio/amplify-ux" element={<AmplifyUx />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              

            </Routes>
          </div>
          <Footer />
        </div>
      
      </BrowserRouter>
        
    </div>
   
  )
}

export default App;
import {useState} from "react";
import React from 'react';
import { Link } from 'react-router-dom';
import footerLogo from '../matt-logo-footer-text-light.png'
import footerLogoDark from '../matt-logo-footer-text-dark.png'


// Modal.setAppElement('#root'); // Set the app element for react-modal

function Footer() {
  function getDate() {
    const today = new Date();
    const year = today.getFullYear();
    return `${year}`;
  }
  const [currentDate, setCurrentDate] = useState(getDate());
  return (
    <footer className="footer">
      <div className="content has-text-centered">
        <p>
          Designed by <strong> Matt Whitfield </strong> using <a href="https://bulma.io">Bulma</a> and
        <a href="https://react.dev/" target="_blank"> React</a>.</p>
        <Link className="button is-text" to="privacy-policy">Privacy Policy</Link>
      </div>
      <div className="content has-text-centered">
      <Link to="/">
        <picture>
            <source media="(prefers-color-scheme: dark)" srcSet={footerLogoDark} />
            <source srcSet={footerLogo} />
            <img className="matt-footer-logo" alt="matt whitfield" />
        </picture>
      </Link>
          <p className="is-size-7">copyright {currentDate}</p>
      </div>

    </footer>
  );
  };

  


export default Footer;
import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';


const items = [
    {
      path: '/portfolio/food-friendly',
      src: 'https://mattwhitfield.xyz/Media/food-friendly-daily-specials.webp',
      category: 'UX Design',
      title: 'Food Friendly',
      description: 'An application to streamline restaurant communication and improve customer experience.',
    },
    {
      path: '/portfolio/amplify-ux',
      src: 'https://mattwhitfield.xyz/Media/amplify-ux/amplify-thumbnail.webp',
      category: 'Product Management',
      title: 'Amplify.ux',
      description: 'A Browser Companion for Emotional Resilience and a Brighter, more Personable Job Search.',
    },
    
      
  ];

const WorkGallery = () => {
  return (
    <div className="grid is-col-min-8">
      {items.map((item, index) => (
        <div className='cell' key={index}>
          <Link className="box" to={item.path}>
            <div>
              <article className='media'>
                <figure className='media-left'>
                  <p className='image is-128x128'>
                    <img src={item.src} alt={item.alt} />
                  </p>
                </figure>
                <div className='media-content'>
                  <div className='content has-text-left'>
                    <span className='tag'>{item.category}</span>
                    <p>
                      <br />
                      <strong className='title is-5'>{item.title}</strong>
                      <br />
                      {item.description}
                    </p>
                  </div>
                </div>
              </article>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default WorkGallery;
function ProductPage() {
    return (
        <div className="hero">
            <div className="hero-content">
                <p className="title">Product Page</p>
                <p className="subtitle">This is the product page</p>
            </div>
        </div>
        );
}
export default ProductPage;
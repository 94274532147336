import React, { useState, useEffect } from 'react';
import ProgressBar from '../../components/ProgressBars';
import ProgressBars from '../../components/ProgressBars';
function AboutPage() {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
          
    const [currentWord, setCurrentWord] = useState(0);
    const words = ['Craft Coffee', 'Beer', 'Running', 'Frisbee 🐾', 'Pizza'];

    useEffect(() => {
        const interval = setInterval(() => {
        if (currentWord < words.length - 1) {
            setCurrentWord(currentWord + 1);
        } else {
            setCurrentWord(0);
        }
        }, 1250);

    return () => {
      clearInterval(interval);
    };
  }, [currentWord]);

return (
<div>
    <section className="hero is-small">
    <div className="hero-body">
        <div className="columns is-align-items-center">
            <div className="column has-text-centered">
                <article className="block">
                <p className="title is-1">{words[currentWord]}</p>
                <div className="block">
                <p className="title is-6">Things I love</p>
                <h1 className='subtitle is-6'>– Matt Whitfield</h1>
                </div>
                </article>
                    
                
            </div>
            <div className="column is-one-third">
                <figure className="image is-square">
                    <img className="is-rounded"src="https://mattwhitfield.xyz/Media/matt-whitfield-bloomsday-2021-400x400.webp" alt="Matt Running Bloomsday"/>
                </figure>
            </div>
        </div>
        
    </div>
    </section>
    <section className="section">
    <article className="container has-text-centered">
        <h2 className="title">Signature Skills</h2>
        <p classsName="subtitle">Fifteen years acting professionally while waiting tables part-time taught me how to be a team player and communicate effectively. Taking on end-to-end marketing for a luxury winery taught me how to learn on the fly <em>and</em> solve problems. Exploring opportunities in Content Writing, UX Design, Web Design, and Product Management taught me resilience and adaptability.</p>
    </article>
    <section className="section">
    <div className="grid is-col-min-12 is-gap-4.5">
        {/* Skill Item Card */}
        <div className="cell">
            <div className="box has-text-centered">
                <div className="block">
                <h3 className="title is-4">
                    Adaptability
                </h3>
                </div>
                <div className="block">
                    <progress className="progress is-large" value="97"max="100"></progress>
                </div>
            </div>
        </div>
         {/* Skill Item Card */}
         <div className="cell">
            <div className="box has-text-centered">
                <div className="block">
                <h3 className="title is-4">
                    Communication
                </h3>
                </div>
                <div className="block">
                    <progress className="progress is-large" value="88" max="100"></progress>
                </div>
            </div>
        </div>
         {/* Skill Item Card */}
         <div className="cell">
            <div className="box has-text-centered">
                <div className="block">
                <h3 className="title is-4">
                    Collaboration
                </h3>
                </div>
                <div className="block">
                    <progress className="progress is-large" value="100" max="100"></progress>
                </div>
            </div>
        </div>
         {/* Skill Item Card */}
         <div className="cell is-row-span-2">
            <div className="box has-text-centered">
                <div className="block">
                <h3 className="title is-4">
                    Problem Solving
                </h3>
                </div>
                <div className="block">
                    <progress className="progress is-large" value="90" max="100"></progress>
                </div>
            </div>
        </div>
        
        
        
    </div>
    </section>
    
    <section className="section">
        <div className='block'>
            <h1 className="title is-2 has-text-centered">About Me</h1>
        </div>
        <article className="content">
                <h2 className='has-text-centered-mobile'>What I Care About</h2>
                <p>Empowering people and driving solutions is what motivates me. My insatiable desire to support those around me translates into fostering inclusive team culture and providing memorable customer interactions.

                    An actor, content marketer, and restaurant professional — I bring a singular perspective to the table. My deep understanding of storytelling's impact on business outcomes fuels my desire to create memorable experiences. I’m exceptional at meeting people where they are, developing authentic rapport, and tailoring my communication approach to meet individual needs, for both customers and teammates.
                </p>
                <p>
                        My journey into tech began when I was in marketing. An effort to improve the customer experience for an e-commerce site led to an exploration of UX design. I completed the Google UX Design Certificate because I fell in love with the design thinking approach to problem solving. 
                    </p>
            </article>
            <article className="content">
                <h2 className='has-text-centered-mobile'>Empathy 🤝 Problem Solving</h2>
                <p>I worked at Arby’s through college, slicing roast beef to cover my tuition. That’s where I first learned an acronym that changed the way I approach people, problems, and conflict. Listen, apologize, solve, and thank, (LAST) was explained during a cheesy corporate video that I probably should have brushed off like my fellow coworkers. Something about it resonated with me though, and I took it to heart. At some point I swapped ‘apologize’, for ‘acknowledge’, transforming the approach into a magic problem-solving elixir.
                </p>
                <p>
                As a server at an iconic NYC restaurant, I put LAST to work and quickly became the go-to person for handling ‘exceptional guests’. Exceptional, in this case, meant exceptionally difficult, or exceptionally hard to please. After learning about human-centered design, I finally realized why the LAST approach is so powerful: empathy. Listening to someone’s problem and acknowledging how it makes them feel is pure empathy, and as it turns out, a foundational part of the UX design process.
                </p>
                </article>
        
       
    </section>
    
    </section>
</div>
);
}
export default AboutPage;
function UXDesignPage() {
    return (
        <div className="hero">
            <div className="hero-content">
                <p className="title">UXDPage</p>
                <p className="subtitle">This is the user experience design page</p>
            </div>
        </div>
        );
}
export default UXDesignPage;